<template lang="html">
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-12">
      <div class="card">
        <div class="card-body form-card">
          <div class="form-group row">
            <div class="col-2">
              <label class="required">Tên</label>
            </div>
            <div class="col-10">
              <el-input
                placeholder="Tên"
                v-model="form.name"
                :class="errors.has('name')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="name"
                data-vv-as="Tên"></el-input>
              <span class="text-danger" v-if="errors.has('name')">{{ errors.first('name') }}</span>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-2">
              <label>Tạo nhiều mã</label>
            </div>
            <div class="col-10">
              <el-switch v-model="is_create_multiple_code"></el-switch>
            </div>
          </div>
          <div class="form-group row" v-if="!is_create_multiple_code">
            <div class="col-2">
              <label>Code</label>
            </div>
            <div class="col-10">
              <el-input
                placeholder="Mã"
                v-model="form.code">
              </el-input>
            </div>
          </div>
          <div class="form-group row" v-if="is_create_multiple_code">
            <div class="col-2">
              <label>Random code</label>
            </div>
            <div class="col-10">
              <div class="row">
                <div class="col-4"><el-input placeholder="Tiền tố" v-model="form.prefix"></el-input></div>
                <div class="col-4"><el-input placeholder="Số ký tự random" v-model="form.character_length" type="number"></el-input></div>
                <div class="col-4"><el-input placeholder="Hậu tố" v-model="form.suffixes"></el-input></div>
              </div>
            </div>
          </div>

          <div class="form-group row" v-if="is_create_multiple_code">
            <div class="col-2">
              <label>Số lượng mã code</label>
            </div>
            <div class="col-10">
              <el-input placeholder="Số lượng mã code" v-model="form.quantity" type="number"></el-input>
            </div>
          </div>

          <div class="form-group row" v-if="is_create_multiple_code">
            <div class="col-2">
              <label>Điều kiện áp dụng</label>
            </div>
            <div class="col-10">
              <el-select class="full-width" v-model="form.coupon_filters" placeholder="Chọn điều kiện lọc" multiple>
              <el-option
                v-for="(item, index) in coupon_filters"
                :key="index"
                :label="item.label"
                :value="item.key">
              </el-option>
            </el-select>
            </div>
          </div>

          <div class="form-group row" v-show="is_create_multiple_code && form.filters.length">
            <div class="col-2">
              <label>Chọn sự kiện</label>
            </div>
            <div class="col-10">
              <el-select class="full-width" multiple v-model="form.filter_race_ids" placeholder="Chọn sự kiện">
                <el-option
                  v-for="item in races"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="form-group row" v-if="is_create_multiple_code && form.filters.length">
            <div class="col-2">
              <label>Số thứ tự</label>
            </div>
            <div class="col-10">
              <div class="row">
                <div class="col-6">
                  <el-input type="number" placeholder="Bắt đầu" v-model="form.user_index_start"></el-input>
                </div>
                <div class="col-6">
                  <el-input type="number" placeholder="Kết thúc" v-model="form.user_index_end"></el-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-2">
              <label>Sử dụng nhiều lần</label>
            </div>
            <div class="col-10">
              <el-switch v-model="form.is_multiple_use"></el-switch>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-2">
              <label>Miễn phí</label>
            </div>
            <div class="col-10">
              <el-switch v-model="is_free"></el-switch>
            </div>
          </div>
          <div class="form-group row" v-if="!is_free">
            <div class="col-2">
              <label class="required">Thể loại</label>
            </div>
            <div class="col-10">
              <el-select
                :class="errors.has('type')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="type"
                data-vv-as="Thể loại"
                class="full-width"
                v-model="form.discount_type">
                <el-option
                v-for="(item, index) in discount_type"
                :key="index"
                :label="item"
                :value="item">
                </el-option>
              </el-select>
              <span class="text-danger" v-if="errors.has('type')">{{ errors.first('type') }}</span>
            </div>
          </div>
          <div class="form-group row" v-if="!is_free && form.discount_type">
            <div class="col-2">
              <label class="required" v-if="form.discount_type == 'value'" >Giảm giá (đ)</label>
              <label class="required" v-if="form.discount_type == 'percent'" >Giảm giá (%)</label>
            </div>
            <div class="col-10">
              <el-input
                type="number"
                placeholder="Giảm giá"
                class="full-width"
                v-model="form.discount"
                :class="errors.has('discount')?'border-danger':''"
                v-validate="'required|min_value:0'"
                data-vv-name="discount"
                data-vv-as="Giá trị">
              </el-input>
              <span class="text-danger" v-if="errors.has('discount')">{{ errors.first('discount') }}</span>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-2">
              <label class="required">Số lượt sử dụng</label>
            </div>
            <div class="col-10">
              <el-input
                type="number"
                placeholder="Số lượt sử dụng"
                v-model="form.amount"
                :class="errors.has('amount')?'border-danger':''"
                v-validate="'required|min_value:1'"
                data-vv-name="amount"
                data-vv-as="Số lượt sử dụng">
              </el-input>
              <span class="text-danger" v-if="errors.has('amount')">{{ errors.first('amount') }}</span>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-2">
              <label>Ghi chú</label>
            </div>
            <div class="col-10">
              <el-input
                type="textarea"
                :rows="3"
                placeholder="Ghi chú"
                v-model="form.note">
              </el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12 sidebar-right">
      <div class="card">
        <div class="card-body form-card">

          <div class="form-group">
            <label>Nguồn</label>
            <el-select class="full-width" v-model="form.type" placeholder="Chọn nguồn coupon">
              <el-option
                v-for="item in sources"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </div>

          <div class="form-group">
            <label>Áp dụng cho users</label>
            <el-select class="full-width" v-model="form.filters" placeholder="Chọn điều kiện lọc" multiple>
              <el-option
                v-for="(item, index) in user_filters"
                :key="index"
                :label="item.label"
                :value="item.key">
              </el-option>
            </el-select>
          </div>

          <div class="form-group">
            <label>Đối tượng áp dụng</label>
            <el-select class="full-width" v-model="form.object" placeholder="Chọn nguồn coupon">
              <el-option
                v-for="item in objects"
                :key="item.value"
                :label="item.title"
                :value="item.value">
              </el-option>
            </el-select>
          </div>

          <div class="form-group" v-if="form.object === 'race'">
            <label>Chọn sự kiện</label>
            <el-select class="full-width" multiple v-model="form.race_ids" placeholder="Chọn sự kiện">
              <el-option
                v-for="item in races"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
          </div>

          <div class="form-group" v-if="form.object === 'addon'">
            <label>Chọn sản phẩm</label>
            <el-select class="full-width" multiple v-model="form.addon_ids" placeholder="Chọn sản phẩm">
              <el-option
                v-for="item in products"
                :key="item.value"
                :label="item.title"
                :value="item.value">
              </el-option>
            </el-select>
          </div>

        </div>
      </div>

      <div class="card">
        <div class="card-body form-card">
          <div class="form-group">
            <label class="required">Thời gian bắt đầu</label>
            <el-date-picker
              class="full-width"
              v-model="form.start_at"
              type="datetime"
              range-separator="To"
              start-placeholder="Bắt đầu"
              end-placeholder="Bắt đầu"
              value-format="yyyy-MM-dd HH:mm:ss"
              :class="errors.has('start_at')?'border-danger':''"
              v-validate="'required'"
              data-vv-name="start_at"
              data-vv-as="Ngày bắt dầu">
            </el-date-picker>
            <span class="text-danger" v-if="errors.has('start_at')">{{ errors.first('start_at') }}</span>
          </div>

          <div class="form-group">
            <label class="required">Thời gian kết thúc</label>
            <el-date-picker
              class="full-width"
              v-model="form.end_at"
              type="datetime"
              range-separator="To"
              start-placeholder="Kết thúc"
              end-placeholder="Kết thúc"
              value-format="yyyy-MM-dd HH:mm:ss"
              :class="errors.has('end_at')?'border-danger':''"
              v-validate="'required|earlier'"
              data-vv-name="end_at"
              data-vv-as="Ngày kết thúc">
            </el-date-picker>
            <span class="text-danger" v-if="errors.has('end_at')">{{ errors.first('end_at') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="page-action">
        <el-button @click="cancel">Quay lại</el-button>
        <el-button type="primary" @click="save">Lưu lại</el-button>
      </div>
    </div>
    <loading :active.sync="loading"></loading>

    <el-dialog title="Danh sách mã code" :visible.sync="is_show_codes" width="50%">
      <div class="row">
        <div class="col-12" style="max-height: 300px; overflow-y: auto;">
          <div v-for="(item, index) of codes" :key="index">{{ item }}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="sm" @click="is_show_codes = false">Đóng</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>

import { Select, Option, Input, Button, DatePicker, Switch, Message, Dialog } from 'element-ui';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components: {
    ElInput: Input,
    ElButton: Button,
    ElSwitch: Switch,
    ElDatePicker: DatePicker,
    ElSelect: Select,
    ElOption: Option,
    ElDialog: Dialog,
    Message,
    Loading
  },

  beforeCreate() {
    window.AP = this;
    this.$store.dispatch('fetchRacesShort', '?status=0,1');
    this.$store.dispatch('fetchAddons');
  },

  created() {
    let self = this;
    this.$validator.extend('earlier', {
      getMessage(field, val) {
        return 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu'
      },
      validate(value, field) {
        let startParts = self.form.start_at
        let momentStart = new Date(startParts).getTime();
        let momentEnd = new Date(value).getTime();
        return momentEnd > momentStart;
      }
    })
  },

  data() {
    return {
      loading: false,
      form: {
        discount_type : '',
        discount: 0,
        type: 'irace',
        object: 'all',
        coupon_filters: [],
        filters: []
      },
      is_free: true,
      discount_type: ['percent', 'value'],
      sources: ['irace', 'partner'],
      objects: [{
        title: 'Tất cả',
        value: 'all'
      }, {
        title: 'Race',
        value: 'race'
      }, {
        title: 'Tiện ích',
        value: 'addon'
      }],
      is_create_multiple_code: false,
      is_show_codes: false,
      codes: [],
      user_filters: [
        {
          key: 'completed_profile',
          label: 'User đã hoàn thành profile'
        }
      ],
      coupon_filters: [{
        key: 'early_registration',
        label: 'Đăng ký sớm'
      },
      {
        key: 'top_completed',
        label: 'Hoàn thành đầu tiên'
      }]
    }
  },
  computed: {
    races() {
      let data = this.$store.state.shortRaces;
      return data;
    },

    products() {
      let data = this.$store.state.addons;
      let arr = [];
      for (let item of data) {
        arr.push({
          value: item.id,
          title: item.title
        });
      }
      return arr;
    }
  },
  mounted() {
    this.$store.dispatch('setPageTitle', 'Tạo coupon');
    this.$store.dispatch('setCurrentActions', [{
      label: 'Lưu lại',
      type: 'primary',
      icon: '',
      callback: this.save
    }])

  },
  methods: {
    cancel() {
      this.$router.push({name: 'AllCoupons'});
    },

    async save() {
      let self = this;
      this.$validator.validateAll().then( async function (result) {
        if (!result) return;

        if (self.is_create_multiple_code) {
          if (!self.form.character_length) {
            return Message({ message: 'Chưa nhập số ký tự random', type: 'error'});
          }

          if (!self.form.quantity) {
            return Message({ message: 'Chưa nhập số lượng mã code', type: 'error'});
          }

        } else {
          if (!self.form.code) {
            return Message({ message: 'Chưa nhập mã code', type: 'error'});
          }
        }

        self.form.is_create_multiple_code = self.is_create_multiple_code;

        self.form.is_free = self.is_free;
        if (self.is_free) self.form.discount = 0;

        self.form.object_ids = self.form.object == 'race' ? self.form.race_ids : self.form.addon_ids;

        self.loading = true;

        await self.$store.dispatch('storeCoupon', self.form).then(res => {

          Message({ message: 'Tạo mới thành công', type: 'success'});

          if (self.is_create_multiple_code == 1) {
            self.codes = res.data.codes.slice();
            self.is_show_codes = true;
          } else {
            setTimeout(_ => {
              self.$router.push('/coupons/' + res.data.coupon_id);
            }, 1000);
          }

        }, error => {
          Message({
            dangerouslyUseHTMLString: true,
            message: error,
            type: 'error'
          });
        });

        self.loading = false;

      })
    }
  },
  watch: {
    'form.discount': function (newVal, oldVal) {
      if (!this.form.discount_type) {
        return;
      }
      switch (this.form.discount_type) {
        case 'value':
          break;
        case 'percent':
          if (newVal < 0) { this.form.discount = 0 }
          if (newVal > 100) { this.form.discount = 100 }
          break;
        default:
          break;
      }
    },
    'form.discount_type': function(newVal, oldVal) {
      if (newVal) {
        this.form.discount = 0;
      }
    }
  },
  destroyed(){
    this.$store.dispatch('setCurrentActions', []);
    this.form = {};
  }
}
</script>